<template>
    <div class="dropDown_wrapper" ref="dropdown" :style="isShowAllNav ? '' : 'height:0;border:none;'">
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, onBeforeUnmount, computed, toRaw, watch } from 'vue'
const isShowAllNav: any = ref(false)
const dropdown: any = ref(null)
const props = defineProps({
    isShowAllNav: {
        type: Boolean,
        default: false
    },
    element: {
        type: Object,
        default: {}
    },
    height: {
        type: String,
        default: ''
    },
    width: {
        type: String,
        default: ''
    }
})
watch(() => props.isShowAllNav, () => {
    isShowAllNav.value = !isShowAllNav.value
}, { deep: true })

const watchpopover = (event: any) => {
    const el1: any = dropdown.value
    // document.querySelector('.dropDown') || document.documentElement.querySelector('.dropDown') || document.body.querySelector('.dropDown')
    const el2: any = props.element
    if (el1 !== null && !(event.target == el2) && !el1.contains(event.target)) {
        isShowAllNav.value = false
    }
}
onMounted(() => {
    document.addEventListener('click', watchpopover)
})
onBeforeUnmount(() => {
    document.removeEventListener('click', watchpopover)
})
</script>

<style lang="scss" scoped>
.dropDown_wrapper {
    position: absolute;
    bottom: 80px;
    right: 0;
    border: 1px solid #d4d4d488;
    background-color: #fff;
    z-index: 11;
    height: calc(100vh - 146px);
    width: 418px;
    border-radius: 5px;
    font-size: 13px;
    overflow: hidden;
    transition: height .3s ease;
}
</style>