<template>
    <div class="playbackinterface_container" ref="playBackIntenface" :style="store.state.isShowPlayback ? '' : 'height:0;'">
        <div class="play_playbackinterface_container">
            <div class="play_body">
                <div class="left">
                    <img class="play_bar_support" src="@/assets/image/play-bar-support.png" alt="">
                    <img class="play_bar" :style="store.state.playState ? ' transform: scale(0.5) rotate(0);' : ''"
                        src="@/assets/image/play-bar.png" alt="">
                    <div class="cd_wrapper">
                        <img :src="store.state.song.alpic" alt=""
                            :style="store.state.playState ? 'animation-play-state: running;' : ''">
                    </div>
                </div>
                <div class="right">
                    <div class="songInfo">
                        <div class="songInfo_t">
                            {{ store.state.song.name }}
                        </div>
                        <div class="songInfo_m">
                            <span class="singer">歌手: {{ store.state.song.ar }}</span>
                            <span class="album">专辑: {{ store.state.song.alname }}</span>
                            <span class="source">来源: {{ store.state.song.source }}</span>
                        </div>
                        <div class="songInfo_b">

                        </div>
                    </div>
                    <Lyrics :lyric="lyric" />
                </div>
            </div>
            <!-- <Commentplace :data="commentlist" :loadmodel="1" :title="`全部评论`"></Commentplace> -->
            <Commentplace :data="commentlist" :loadmodel="2" :title="`最新评论`" :totaldatalength="totaldatalength"
                :isLoading="isLoading">
            </Commentplace>
        </div>
    </div>
    <div class="actionComment" v-if="store.state.isShowPlayback">
        <i class="iconfont">&#xe8ae;</i>
        <span>快来说点什么吧</span>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, reactive, watch, provide, toRaw, onBeforeUnmount } from 'vue';
import Commentplace from '@/components/Commentplace.vue';
import Lyrics from '@/components/Lyrics.vue';
import { useStore } from 'vuex'
import { getcommentmusic, getLyrics } from '@/apis/song'
import debounce from '@/util/debounce'
import { assigncommentlist } from '@/util/assigncommentlist'

const store = useStore()
let totaldatalength = ref()
const playBackIntenface: any = ref(null)
let commentlist: any = ref()
const isLoading = ref(false)
const lyric: any = ref()
watch(() => store.state.playIndex, () => {
    commentlist.value = null
    isLoading.value = true
    lyric.value = null
})
watch(() => store.state.song, debounce(async (newVal) => {
    if (newVal?.url) {
        try {
            const { total, comments } = await getcommentmusic(store.state.song.id, 30, 0)
            totaldatalength.value = total
            commentlist.value = assigncommentlist(comments)
            isLoading.value = false
            const { lrc } = await getLyrics(store.state.song.id)
            let newArr: any = []
            lrc.lyric.split('\n').forEach((line: any) => {
                const parts = line.split(']');
                const text = parts.pop().trim()
                parts.forEach((part: any) => {
                    const matchs = part.match(/\[(\d{2}):(\d{2})\.(\d{2,3})/);
                    if (matchs) {
                        const timeStamp = parseInt(matchs[1]) * 60 * 1000 + parseInt(matchs[2]) * 1000 + (matchs[3].length == 2 ? parseInt(matchs[3]) * 10 : parseInt(matchs[3]))
                        newArr.push({ timeStamp, text })
                    }
                })
            })

            lyric.value = newArr.sort((a: any, b: any) => a.timeStamp - b.timeStamp);
        } catch (error) {
            console.log(error)
        }
    }
}, 100), { deep: true })
let currentPage: any = ref(1)
const getCurrentPage = async (value: any) => {
    currentPage.value = value
    playBackIntenface.value.scrollTop = 560
    const { comments } = await getcommentmusic(store.state.song.id, 30, (value - 1) * 30)
    commentlist.value = assigncommentlist(comments)
}
provide('getCurrentPage', getCurrentPage)
</script>

<style lang="scss" scoped>
.playbackinterface_container {
    position: absolute;
    bottom: 70px;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 130px);
    overflow-y: scroll;
    background-color: #fff;
    transition: height .3s ease;

    .play_playbackinterface_container {
        position: absolute;
        top: 0;
        left: 50%;
        box-sizing: border-box;
        transform: translateX(-50%);
        width: 900px;
        height: calc(100vh - 137px);

        .play_body {
            height: 550px;
            width: 100%;
            display: flex;

            .left {
                position: relative;
                width: 55%;

                .play_bar_support {
                    transform: scale(0.3);
                    position: absolute;
                    top: -36px;
                    left: 207px;
                    z-index: 6;
                }

                .play_bar {
                    position: absolute;
                    left: 236px;
                    top: 3px;
                    transform-origin: 0 0;
                    transition: transform 1s ease;
                    transform: scale(0.5) rotate(-30deg);
                    z-index: 5;
                }

                // .play_bar_rotate {
                //     transform: scale(0.5) rotate(0);
                // }

                .cd_wrapper {
                    position: absolute;
                    top: 73px;
                    left: 85px;
                    width: 280px;
                    height: 280px;
                    background-color: #000;
                    border: 16px solid #e4e4e4ee;
                    border-radius: 50%;
                    display: flex;

                    img {
                        width: 70%;
                        height: 70%;
                        margin: auto;
                        border-radius: 50%;
                        animation-name: imgrotate;
                        animation-duration: 30s;
                        animation-iteration-count: infinite;
                        animation-timing-function: linear;
                        animation-play-state: paused;

                        @keyframes imgrotate {
                            from {
                                transform: rotate(0deg);
                            }

                            to {
                                transform: rotate(360deg);
                            }
                        }
                    }
                }
            }

            .right {
                width: 45%;

                .songInfo {
                    height: 140px;
                    color: $font-color-transparent;
                    padding-top: 20px;
                    box-sizing: border-box;

                    .songInfo_t {
                        @include text-ellipsis-multi(2);
                        color: #000;
                        font-size: $font-size-title-lg;
                    }

                    @mixin samestyle() {
                        font-size: $font-size;
                        height: 25px;
                        line-height: 25px;
                    }

                    .songInfo_m {
                        @include samestyle();
                        display: flex;
                        flex-shrink: 0;
                        justify-content: space-between;

                        span {
                            @include text-ellipsis;
                            width: 125px;
                        }
                    }

                    .songInfo_b {
                        @include samestyle();
                        @include text-ellipsis;
                    }
                }
            }
        }

        :deep(.commentplace_container .loadmore2) {
            padding-bottom: 100px;
        }
    }
}

.actionComment {
    @include absolute-center('row');
    bottom: 100px;
    padding: 7px 13px;
    border-radius: 20px;
    background-color: #F1F1F1;
    cursor: pointer;
    font-size: 13px;
    transition: all .3s ease;
    z-index: 10;

    &:hover {
        background-color: #E1E1E1;
    }
}
</style>