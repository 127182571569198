<template>
    <div class="lyrics-container" ref="lyricsContainer" v-if="props.lyric">
        <div v-for="(line, index) in props.lyric" :key="index" :class="{ active: currentLine == index }" class="lyric-line">
            {{ line.text }}
        </div>
    </div>
    <LoadingCanvas v-else :style="`height:100px;background-color: #fff;width:45%`" />
</template>
  
<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import LoadingCanvas from './LoadingCanvas.vue';
const store = useStore()
const props = defineProps({
    lyric: {
        type: Object,
        default: () => { }
    }
})
watch(() => props.lyric, (newVal) => {

    // console.log(props.lyric)
})
// 假设的歌词数据，每一行包含时间戳（秒）和歌词文本
const lyrics = [
    { time: 1, text: 'Hello, how are you?' },
    { time: 5, text: 'I am fine, thank you.' },
    // ...更多歌词
];

// 当前活动的歌词行
const currentLine: any = ref(0);

// 歌词容器的引用
const lyricsContainer = ref<any>(null);

watch(() => store.state.audioCurrentTime, (newVal, oldVal) => {
    updateCurrentLine(parseInt(newVal))


})

// 更新歌词行的函数
const updateCurrentLine = (currentTime: any) => {
    for (let i = 0; i < props.lyric?.length - 1; i++) {
        if (currentTime >= props.lyric[i].timeStamp - 500 && (!props.lyric[i + 1] || currentTime < props.lyric[i + 1].timeStamp)) {
            currentLine.value = i
            break
        }
    }
};

// 模拟音乐播放器的当前播放时间
const currentTime = ref(0);

// 监听当前行的变化来更新滚动位置
watch(currentLine, (newLine) => {
    const container = lyricsContainer.value;
    if (!container) return;

    const activeLineEl = container.children[newLine];
    if (activeLineEl) {
        const offset = activeLineEl.offsetTop - container.clientHeight / 2 + activeLineEl.clientHeight / 2;
        container.scrollTo({ top: offset, behavior: 'smooth' });
    }
});

// 模拟播放器时间更新
// setInterval(() => {
//     currentTime.value++;
//     updateCurrentLine(currentTime.value);
// }, 1000);

onMounted(() => {
    // console.log(props.lyric)
    // updateCurrentLine(currentTime.value);
});
</script>
  
<style scoped lang="scss">
.lyrics-container {
    position: relative;
    overflow: hidden;
    height: 200px;
    /* 容器的固定高度，确保只显示中间几行歌词 */
}

.lyric {
    transition: color 0.3s;
    opacity: 0.5;
    /* 默认所有歌词行都稍微透明 */
}

.active {
    color: #fff;
    /* 当前播放的歌词行颜色 */
    opacity: 1;
    /* 当前播放的歌词行完全不透明 */
}

.lyrics-container::before,
.lyrics-container::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 50%;
    /* 遮罩层覆盖上下各50% */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    pointer-events: none;
    /* 防止遮罩层阻挡交互 */
}

.lyrics-container::after {
    bottom: 0;
    transform: rotate(180deg);
}</style>
  