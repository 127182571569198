export const assigncommentlist = (value: any) => {
    const newArr = value.map((item: any) => {
        if (item.beReplied.length) {
            return {
                ...item, userId: item.user.userId, nickname: item.user.nickname, avatarUrl: item.user.avatarUrl, beRepliedCommentId: item.beReplied[0].beRepliedCommentId, beReplieduserId: item.beReplied[0].user.userId, replynickname: item.beReplied[0].user.nickname, replycontent: item.beReplied[0].content
            }
        }
        else {
            return {
                ...item, userId: item.user.userId, nickname: item.user.nickname, avatarUrl: item.user.avatarUrl
            }
        }
    })
    return newArr
}