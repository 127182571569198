import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import installElement from './util/element'
// import SocketServer from './util/socket_service'
// SocketServer.Instance.connect()
const app = createApp(App)
// app.config.globalProperties.$socket = SocketServer.Instance
installElement(app)
app.use(store)
app.use(router)
app.mount('#app')
