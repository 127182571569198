import axios, { AxiosInstance, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from "axios"
import { ResultData } from '@/types/resultData'


class RequestHttp {
    service: AxiosInstance

    public constructor() {
        //实例创建
        this.service = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
            timeout: 5000,
            withCredentials: true
        })
        //请求拦截
        this.service.interceptors.request.use((config: InternalAxiosRequestConfig) => {
            const token = localStorage.getItem('token') || ''
            if (token) {
                config.headers.Authorization = token
            }
            return config
        }, (err: AxiosError) => {
            return Promise.reject(err)
        })
        //响应拦截
        this.service.interceptors.response.use((response: AxiosResponse) => {
            const { data } = response
            return data
        }, (err: AxiosError) => {
            return Promise.reject(err)
        })
    }
    //请求方式
    get(url: string, params?: object): Promise<ResultData> {
        return this.service.get(url, { params })
    }
    post(url: string, params?: object): Promise<ResultData> {
        return this.service.post(url, { params })
    }
}

export default new RequestHttp()





