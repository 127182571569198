<template>
    <div class="globalbottom_wrapper">
        <div class="audio_progress" v-if="store.state.song?.name">
            <el-slider v-model="progressValue" size="small" :show-tooltip="false" :max="1000" @input="progressChange" />
        </div>
        <div class="globalbottom_wrapper_left" v-if="store.state.song?.name">
            <div class="pic" @click="showplay">
                <img :src="store.state.song.alpic" alt="">
                <div class="picmask">
                    <i class="iconfont" :class="store.state.isShowPlayback ? 'iconrotate' : ''">&#xe695;</i>
                </div>
            </div>
            <div class="attachment">
                <div class="title">{{ store.state.song.name }}</div>
                <div class="duration">
                    <span>
                        <TimeFormat :time="store.state.audioCurrentTime" :mode="`xx:xx`" />
                    </span>/<span>
                        <TimeFormat :time="store.state.song.time ? store.state.song.time : store.state.song.dt"
                            :mode="`xx:xx`" />
                    </span>

                </div>
            </div>

        </div>

        <div class="globalbottom_wrapper_middle" :class="store.state.song?.name ? '' : 'withoutmusic'">
            <i class="iconfont back" @click="nextORback('back')">&#xe607;</i>
            <i class="iconfont action" v-html="!store.state.playState ? '&#xea82;' : `&#xe611;`"
                @click="changePlayState"></i>
            <i class="iconfont next" @click="nextORback('next')">&#x1010d;</i>
        </div>

        <div class="globalbottom_wrapper_right" v-if="store.state.song?.name">
            <i class="iconfont playType" @click="changePlayType" v-html="playTypeIcon"></i>
            <i class="iconfont" v-html="volumeStatus ? '&#xe62a;' : `&#xe620;`" @click="changeVolume"></i>
            <div class="slider-demo-block">
                <el-slider v-model="volumeValue" size="small" />
            </div>
            <i class="iconfont expand" @click="isShowallNav = !isShowallNav" ref="expandIcon">&#xea6f;</i>
            <Dropdown :isShowAllNav="isShowallNav" :element="expandIcon">
                <div class="expand_top">
                    <div class="top_title">当前播放</div>
                    <div class="song_count">总{{ musicList?.length ? musicList.length : 0 }}首</div>
                    <div class="top_tool">
                        <span class="collectall"><i class="iconfont">&#xe615;</i>收藏全部</span>
                        <span class="clearlist" @click="clearAll">清空列表</span>
                    </div>
                </div>
                <div class="expand_body">
                    <div class="item" v-for="(item, index) in musicList" :class="item.isplaying ? 'isplaying' : ''"
                        :key="item.id" @dblclick="playback(index)">
                        <span class="item_name">{{ item.name }}</span>
                        <span class="item_nickname"><span>{{ item.arname }}</span></span>
                        <i class="iconfont linkIcon">&#xe7e2;</i>
                        <div class="item_time">
                            <TimeFormat :time="item.dt" :mode="`xx:xx`" />
                        </div>
                    </div>
                </div>
            </Dropdown>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, reactive, toRaw, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import TimeFormat from './TimeFormat.vue';
import Dropdown from './Dropdown.vue';
const store = useStore()
const router = useRouter()
const volumeValue = ref(100)
const progressValue = ref(0)
const likechange = ref(false)
const expandIcon: any = ref(null)
let isShowallNav: any = ref(false)
const musicList: any = ref()
onMounted(() => {
    const playType = JSON.parse(localStorage.getItem('playType') as any) || 1
    store.commit('changePlayType', playType)
    const playIndex = JSON.parse(localStorage.getItem('playIndex') as any) == null ? -1 : JSON.parse(localStorage.getItem('playIndex') as any)
    store.commit('updatePlayIndex', playIndex)
    nextTick(() => {
        store.commit('changePlayState', false)
    })
    volumeValue.value = store.state.audioVolume * 100
})
// watch(() => store.state.musicListStatus, () => {
//     musicList.value = JSON.parse(localStorage.getItem('musicList') as any) || []
// }, { deep: true, immediate: true })

const playback = (index: number) => {
    store.commit('updatePlayIndex', index)
}
//更改播放类型
const changePlayType = () => {
    store.commit('changePlayType', store.state.playType + 1)
}

//前进后退
const nextORback = (params: string) => {
    let index: number = 0
    if (store.state.playType == 3) {//生成随机数
        //生成 [0,43] 范围内的随机数,var random1 = Math.random()*(43-0)+0;
        index = Math.floor(Math.random() * (musicList.value?.length - 1))
    }
    else {
        switch (params) {
            case 'back':
                index = store.state.playIndex == 0 ? musicList.value?.length - 1 : store.state.playIndex - 1
                break;
            case 'next':
                index = store.state.playIndex == musicList.value?.length - 1 ? 0 : store.state.playIndex + 1
                break;
        }
    }
    store.commit('updatePlayIndex', index)
}
const volumeStatus = ref(true)
const changeVolume = () => {
    volumeStatus.value = !volumeStatus.value
    try {
        const lastVolume = JSON.parse(localStorage.getItem('audioVolume') as any) || 1
        volumeValue.value = volumeStatus.value ? lastVolume * 100 : 0
        store.commit('updateAudioVolume', volumeStatus.value ? lastVolume * 100 : 0)
    } catch (error) {
        console.log(error)
    }
}

const clearAll = () => {
    musicList.value = []
    localStorage.removeItem('musicList')
    store.commit('getSong', {})
}

const progressChange = (e: any) => {
    store.commit('dragprogress', e / 1000 * store.state.audioDuration)
}
watch(() => store.state.playIndex, () => {
    musicList.value = JSON.parse(localStorage.getItem('musicList') as any) || []
    try {
        if (musicList.value?.length && store.state.playIndex !== -1) {
            musicList.value.map((item: any) => {
                item.isplaying = false
            })
            musicList.value[store.state.playIndex].isplaying = true
            localStorage.setItem('musicList', JSON.stringify(toRaw(musicList.value)))
        }
    } catch (error) {
        console.log(error)
    }
}, { deep: true, immediate: true })
watch(() => volumeValue.value, () => {
    store.commit('updateAudioVolume', volumeValue.value / 100)
    volumeStatus.value = volumeValue.value ? true : false
    if (volumeStatus.value) {
        localStorage.setItem('audioVolume', (volumeValue.value / 100) as any)
    }
})
watch(() => store.state.audioProgress, () => {
    progressValue.value = store.state.audioProgress
})

const showplay = () => {
    store.commit('changeShowPlayback')
}
const changePlayState = () => {
    store.commit('changePlayState', store.state.playState ? false : true)
}
let playTypeIcon: any = ref()
watch(() => store.state.playType, (newVal, oldVal) => {
    switch (newVal) {
        case 1:
            playTypeIcon.value = '&#xe6a3;'
            break;
        case 2:
            playTypeIcon.value = '&#xe6a2;'
            break;
        case 3:
            playTypeIcon.value = '&#xe6a0;'
            break;
        case 4:
            playTypeIcon.value = '&#xe6a1;'
            break;
    }
}, { deep: true, immediate: true })
</script>

<style lang="scss" scoped>
.globalbottom_wrapper {
    position: fixed;
    bottom: 0;
    height: 70px;
    width: 100vw;
    border-top: 1px solid #e2e2e2;
    background-color: #fff;
    z-index: 12;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px 10px;

    :deep(.el-slider__button) {
        border: none;
        background-color: #F1DBAD;
        width: 10px;
        height: 10px;
    }

    :deep(.el-slider__bar) {
        background-color: #F1DBAD;
    }

    .audio_progress {
        position: absolute;
        left: 0;
        top: -13px;
        width: 100%;

        :deep(.el-slider__button) {
            width: 10px;
            height: 10px;
        }

        :deep(.el-slider__runway) {
            height: 2px;

            .el-slider__bar {
                top: -2px;
            }

            .el-slider__button-wrapper {
                top: -17px;
            }
        }
    }

    .globalbottom_wrapper_left {
        height: 100%;
        position: relative;

        .pic {
            height: 55px;
            width: 55px;
            border-radius: 5px;
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);


            img,
            .picmask {
                height: 100%;
                width: 100%;
                border-radius: 5px;

            }

            .picmask {
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -5;
                background-color: rgba(0, 0, 0, 0.4);
                overflow: hidden;
                flex-direction: column;

                .iconfont {
                    margin: auto;
                    color: #fff;
                    transition: transform .5s ease;
                }

                .iconrotate {
                    transform: rotate(180deg);
                }
            }

            &:hover {
                .picmask {
                    z-index: 10;
                }

                img {
                    filter: blur(1px);
                }
            }
        }

        .attachment {
            position: absolute;
            left: 70px;
            height: 50px;
            display: flex;
            bottom: 0;
            max-width: 190px;
            overflow: hidden;
            margin: auto 0;
            flex-direction: column;
            justify-content: space-between;

            .title {
                font-size: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .duration {
                font-size: 13px;
            }
        }

        .iconfont {
            margin-left: 5px;
            margin-top: 10px;
            font-size: 22px;
            cursor: pointer;
            color: #e2e2e2;
        }
    }

    .globalbottom_wrapper_middle {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        display: flex;
        justify-content: center;

        .iconfont {
            color: #F1DBAD;
            cursor: pointer;

            &:hover {
                color: #eed297;
            }
        }

        .back,
        .next {
            font-size: 27px;
            margin: auto 0;
        }

        .action {
            font-size: 40px;
            margin: auto 15px;
        }


    }

    .withoutmusic {
        pointer-events: none;

        .iconfont {
            color: #f1dbad93;
        }
    }

    .globalbottom_wrapper_right {
        height: 100%;
        display: flex;

        .iconfont {
            color: #3a3a3a;
            margin: auto 0;
            font-size: 20px;
            margin-left: 10px;
            cursor: pointer;

            &:hover {
                color: #d8d8d8;
            }
        }

        .expand {
            font-weight: 600;
            font-size: 15px;
            margin-right: 20px;

        }

        .slider-demo-block {
            display: flex;
            align-items: center;
            margin: auto 0;
            width: 140px;
        }

        .slider-demo-block .el-slider {
            margin-top: 0;
            margin-left: 15px;
        }

        .expand_top {
            width: 100%;
            height: 85px;
            position: relative;
            border-bottom: 1px solid #d4d4d488;
            padding: 15px;
            box-sizing: border-box;

            .top_title {
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 10px;
            }

            .song_count {
                font-size: 13px;
                color: #e5e5e5;
            }

            .top_tool {
                position: absolute;
                bottom: 10px;
                right: 10px;
                display: flex;

                .collectall,
                .clearlist {
                    cursor: pointer;
                    font-size: 14px;
                    margin-left: 10px;
                    color: #666666;

                    .iconfont {
                        font-size: 13px;

                        &:hover {
                            color: #000;
                        }
                    }
                }

                .clearlist {
                    color: #507DAF;

                    &:hover {
                        color: #0B58B0;
                    }
                }
            }
        }

        .expand_body {
            width: 100%;
            overflow-y: scroll;
            height: calc(100% - 85px);

            .item {
                padding: 0 15px;
                height: 31px;
                line-height: 31px;
                display: flex;
                box-sizing: border-box;
                width: 100%;
                background-color: #fff;

                &:nth-child(2n) {
                    background-color: #FAFAFA;
                }

                &:hover {
                    background-color: #F5F5F5;
                }

                .item_name,
                .item_nickname {
                    display: block;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .item_name {
                    width: 50%;
                }

                .item_nickname {
                    width: 30%;
                    color: #989898;
                    padding-left: 5px;
                    box-sizing: border-box;

                    span {
                        cursor: pointer;
                    }

                }

                .iconfont {
                    padding: 0 5px;
                    color: #ACACAC;
                    cursor: pointer;
                    margin-left: 0;
                    font-size: 14px;
                }

                .item_time {
                    color: #DADADA;
                    width: 15%;
                }

                .item_nickname,
                .iconfont,
                .item_time {
                    &:hover {
                        color: #000;
                    }
                }
            }

            .isplaying {
                color: red;
            }
        }

    }


}
</style>