<template>
    <audio :src="store.state.song.url" ref="audio" :volume="store.state.audioVolume"></audio>
</template>

<script setup lang="ts">
import { watch, ref, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex';
import { checkmusic, getsongurl } from '@/apis/song'
import debounce from '@/util/debounce'
const store = useStore()
const audio: any = ref(null)
//播放进度条
watch(() => store.state.dragProgress, () => {
    audio.value.currentTime = store.state.dragProgress / 1000
})
watch(() => store.state.audioCurrentTime, () => {
    store.commit('updateAudioProgress')
})


const watchtimeStamp = (event: any) => {
    store.commit('updateAudioCurrentTime', audio.value.currentTime * 1000)
}
try {
    const lastVolume = JSON.parse(localStorage.getItem('audioVolume') as any) || 1
    store.commit('updateAudioVolume', lastVolume)
    localStorage.setItem('audioVolume', lastVolume)

} catch (error) {
    store.commit('updateAudioVolume', 1)
    localStorage.setItem('audioVolume', 1 as any)
    console.log(error)
}
onMounted(() => {
    getSongInfo()
    audio.value?.addEventListener("timeupdate", watchtimeStamp);
    audio.value?.addEventListener("ended", watchifended);

})
onUnmounted(() => {
    audio.value?.removeEventListener("timeupdate", watchtimeStamp)
    audio.value?.removeEventListener("ended", watchifended);
})
const watchifended = (event: any) => {
    const musicList: any = localStorage.getItem('musicList') || []
    const totalCount = (JSON.parse(musicList)).length
    switch (store.state.playType) {
        case 1://循环播放
            if (store.state.playIndex < totalCount - 1) {
                store.commit('updatePlayIndex', store.state.playIndex + 1)
            }
            else {
                store.commit('updatePlayIndex', 0)
            }
            store.commit('changePlayState', false)
            break;
        case 2://单曲循环
            store.commit('changePlayState', false)
            store.commit('updateAudioCurrentTime', 0)
            setTimeout(() => {
                store.commit('changePlayState', true)
            }, 1)
            break;
        case 3://随机播放
            //生成 [0,43] 范围内的随机数,var random1 = Math.random()*(43-0)+0;
            store.commit('updatePlayIndex', Math.floor(Math.random() * (totalCount - 1)))
            store.commit('changePlayState', false)
            break;
        case 4://顺序播放
            if (store.state.playIndex < totalCount - 1) {
                store.commit('updatePlayIndex', store.state.playIndex + 1)
            }
            store.commit('changePlayState', false)
            break;
    }
}
watch(() => store.state.playIndex, () => {
    if (store.state.playIndex !== -1) {
        getSongInfo()
        store.commit('changePlayState', true)
    }
}, { deep: true })

//获取歌曲信息
const getSongInfo = () => {
    store.commit('changePlayState', false)
    const localmusicList: any = JSON.parse(localStorage.getItem('musicList') as any) || []
    const playindex: any = JSON.parse(localStorage.getItem('playIndex') as any) || 0
    let item: any = localmusicList.length ? localmusicList[playindex] : null
    songInfo = item
    if (item && playindex <= localmusicList.length) {
        store.commit('getSong', { ...item, isplaying: false })
        getSongData()
    }
}
let songInfo: any//当前歌曲信息
//防抖获取歌曲url，预防多次切换时请求次数过多
const getSongData = debounce(async () => {
    try {
        const { success } = await checkmusic(songInfo.id)
        if (success) {
            const { data } = await getsongurl(songInfo.id)
            store.commit('getSong', { ...data[0], ...songInfo, isplaying: false })
        }
    } catch (error) {
        console.log(error)
    }
})
//播放状态
watch(() => store.state.playState, debounce(async () => {
    //要用trycatch捕获异步代码(promise)(比如HTMLMediaElement的play()方法)的话得在async函数中(trycatch本是捕获同步代码错误)
    try {
        if (store.state.playState && audio.value.paused) {
            await audio.value.play()
        }
        else if (!store.state.playState && !audio.value.paused) {
            await audio.value.pause()
        }
    } catch (error) {
        console.log(error)
    }
}), { deep: true })
</script>
