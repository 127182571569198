<template>
    <div class="loading_bg">
        <div class="loader"></div>
    </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>
$colors: #9e9e9ea9 #9e9e9ea9 #9e9e9ea9 #9e9e9ea9;
$size: 32px;
$thickness: 3.2px;

// Calculated variables.
$lat: (
    $size - $thickness) / 2;
$offset: $lat - $thickness;

.loading_bg {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000000de;
    z-index: 200;
}

.loader {
    position: relative;
    width: $size;
    height: $size;
    z-index: 200;
    transform: rotate(165deg
    );

&:before,
&:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: $thickness;
    height: $thickness;
    border-radius: $thickness / 2;
    transform: translate(-50%, -50%);
}

&:before {
    animation: before 1.3s infinite;
}

&:after {
    animation: after 1.3s infinite;
}
}

@keyframes before {
    0% {
        width: $thickness;
        box-shadow:
            $lat (
            -$offset) nth($colors, 1),
            (-$lat) $offset nth($colors, 3
        );
}

35% {
    width: $size;
    box-shadow:
        0 (-$offset) nth($colors, 1),
        0 $offset nth($colors, 3);
}

70% {
    width: $thickness;
    box-shadow:
        (-$lat) (-$offset) nth($colors, 1),
        $lat $offset nth($colors, 3);
}

100% {
    box-shadow:
        $lat (
        -$offset) nth($colors, 1),
        (-$lat) $offset nth($colors, 3
    );
}
}

@keyframes after {
    0% {
        height: $thickness;
        box-shadow:
            $offset $lat nth($colors, 2),
            (-$offset) (-$lat) nth($colors, 4);
    }

    35% {
        height: $size;
        box-shadow:
            $offset 0 nth($colors, 2),
            (-$offset) 0 nth($colors, 4);
    }

    70% {
        height: $thickness;
        box-shadow:
            $offset (
            -$lat) nth($colors, 2),
            (-$offset) $lat nth($colors, 4
        );
}

100% {
    box-shadow:
        $offset $lat nth($colors, 2),
        (-$offset) (-$lat) nth($colors, 4);
}
}



/**
 * Attempt to center the whole thing!
 */

html,
body {
    height: 100%;
}

.loader {
    position: absolute;
    top: calc(50% - #{$size / 2});
    left: calc(50% - #{$size / 2});
}
</style>