//防抖
import { onUnmounted } from 'vue'

export default function debounce(fn: (params?: any) => Promise<void> | {} | void, delay = 500) {
    let timer: any = null
    onUnmounted(() => {
        clearTimeout(timer)
    })
    return function (...args: []) {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            if (args) {
                fn(...args)
            }
            else {
                fn()
            }
        }, delay)
    }

}