<template>
  <GlobalTop />
  <LeftNav />
  <router-view />
  <GlobalBottom />
  <PlaybackInterface />
  <Song />
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import GlobalTop from './components/GlobalTop.vue';
import PlaybackInterface from './components/PlaybackInterface.vue'
import LeftNav from './components/LeftNav.vue'
import GlobalBottom from './components/GlobalBottom.vue';
import Song from './components/Song.vue'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex'
const store = useStore()
const route = useRoute()

</script>

<style lang="scss">
@import "./assets/iconfont.css";

* {
  margin: 0;
  padding: 0;
  user-select: none;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

:hover::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #bebebe62;
}

:hover::-webkit-scrollbar-thumb:hover {
  background-color: #75757562;
}

/* 滚动条轨道 */
/* ::-webkit-scrollbar-track {} */
html {
  font-size: 1vw;
}
</style>
